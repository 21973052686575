import styled from "styled-components"
import { breakpoints } from "@breakpoints"

const Wrapper = styled.div`
    overflow-x: clip;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 0;
    margin-top: 100px;

    ${breakpoints.md} {
        margin-top: 200px;
        min-height: 700px;
        padding: 40px 0 150px 0;
        margin-bottom: 0;
    }
`

const Content = styled.div`
    ${breakpoints.md} {
        z-index: 2;
    }

    & button {
        margin-bottom: 160px;
    }
`

const ButtonWrapper = styled.div`
    margin-bottom: 50px;
    margin-top: 50px;

    ${breakpoints.md} {
        margin-bottom: 160px;
    }
`

const Image = styled.div`
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 0;

    ${breakpoints.md} {
        display: block;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: calc(50% - 55px);
        width: 100%;
        max-width: 1120px;
        padding-top: 40px;
    }
`

export { Wrapper, Content, ButtonWrapper, Image }
