import React from "react"
import Template from "../../templates/Template"
import { GridContainer } from "@styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { H1 } from "@headers"
import { Button } from "@common"
import * as S from "./styled"
import { useOptionsQuery } from "@hooks"

const Page404 = () => {
    const { header, button, image } = useOptionsQuery().page404.acf
    return (
        <Template>
            <S.Wrapper>
                <GridContainer>
                    <div className="grid md:grid-cols-12">
                        <S.Content className="md:col-span-5">
                            <H1 content={header}></H1>
                            <S.ButtonWrapper>
                                <Button
                                    title={button.title}
                                    url={button.url}
                                    target={button.target}
                                />
                            </S.ButtonWrapper>
                        </S.Content>

                        <S.Image className="md:col-span-7">
                            <GatsbyImage
                                alt=""
                                image={
                                    image.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </S.Image>
                    </div>
                </GridContainer>
            </S.Wrapper>
        </Template>
    )
}

export default Page404
